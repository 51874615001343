import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    grid-column: 2/3;
    height: 80px;
    z-index: 2;
    div {
        display: block;
        margin: 0 auto;
        width: 1px;
        height: 100%;
        background-color: white;
        opacity: 0.5;
    }
    @media screen and (min-width: 800px) {
        grid-column: 2/14;
        height: 120px;
    }
`;

export default function VerticalLine({ className }) {
    return (
        <Wrapper className={`${className} ct`}>
            <div></div>
        </Wrapper>
    );
}
