import { useEffect, useState } from 'react';

export default function useScrollValue() {
    const [scrollValue, setScrollValue] = useState(0);

    function handleScroll() {
        setScrollValue(window.pageYOffset);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollValue]);
    return scrollValue;
}
