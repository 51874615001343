/* 
As this image has a parallax effet
we need to create a specific component
to avoid render event propagation due to
scroll listener
*/

import React from 'react';
import useScrollValue from '../../utils/useScrollValue';

const MountainParallax = ({ className }) => {
    let offset = useScrollValue();

    return (
        <picture className={className}>
            <source
                media="(min-width: 800px)"
                srcSet="/images/header/desktop/mountain.png"
            />
            <img
                style={{
                    transform: `translateY(${offset * -0.012}vh)`,
                }}
                src="/images/header/mobile/mountain.png"
                alt="sky"
            />
        </picture>
    );
};

export default MountainParallax;
