import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import {
    BufferGeometry,
    Float32BufferAttribute,
    PerspectiveCamera,
    Points,
    PointsMaterial,
    Scene,
    WebGLRenderer,
} from 'three';
import { getRandomFloat, getRandomNumber } from '../../utils/maths';

const CANVAS_SIZE = 350;
const POINT_SIZE = 6;
const POINTS_QTY = 5000;
const CAMERA_DIST = 500;

const CanvasEl = styled.canvas`
    width: ${CANVAS_SIZE}px;
    height: ${CANVAS_SIZE}px;
    z-index: 2;
`;

export class SnowBall extends Component {
    canvas = createRef();
    scene = new Scene();
    camera = new PerspectiveCamera(75, 1, 0.1, 1000);

    // -1 <-> 1 / 0 = centre
    mouseX = 0;
    mouseY = 0;

    xVectors = [];
    yVectors = [];

    init() {
        this.camera.position.z = CAMERA_DIST;

        this.geometry = new BufferGeometry();

        const vertices = [];

        let i = POINTS_QTY;
        while (i--) {
            const x = getRandomNumber(-350, 350);
            const y = getRandomNumber(-350, 350);
            const z = 0;
            vertices.push(x, y, z);
            this.xVectors.push(0);
            this.yVectors.push(0);
        }

        this.geometry.setAttribute(
            'position',
            new Float32BufferAttribute(vertices, 3),
        );

        const material = new PointsMaterial({
            size: POINT_SIZE,
            depthTest: false,
            opacity: 0.5,
            transparent: true,
        });

        this.scene.add(new Points(this.geometry, material));

        this.renderer = new WebGLRenderer({
            canvas: this.canvas.current,
            alpha: true,
            depth: false,
        });
        if (this.props.isMobile) {
            let mobileSize =
                window.innerWidth * 0.88 < CANVAS_SIZE
                    ? window.innerWidth * 0.88
                    : CANVAS_SIZE;
            // factor 0.88 as the grid in mobile is 6% 1fr 6%
            // and we want to to get the 1fr value
            this.renderer.setSize(mobileSize, mobileSize);
        } else {
            this.renderer.setSize(CANVAS_SIZE, CANVAS_SIZE);
        }
    }

    animate = () => {
        requestAnimationFrame(this.animate);
        this.render3d();
    };

    render3d() {
        // decrease mouse during time
        this.mouseX *= 0.95;
        this.mouseY *= 0.95;

        // compute
        for (let i = 0; i < POINTS_QTY; i++) {
            const i3 = i * 3;
            let x = this.geometry.attributes.position.array[i3];
            let y = this.geometry.attributes.position.array[i3 + 1];
            let xVector = this.xVectors[i3];
            let yVector = this.yVectors[i3];

            x += xVector * 3;
            y -= yVector * 3;

            xVector += (Math.random() - 0.5) / 5;
            xVector *= 0.99;
            yVector += (Math.random() - 0.5) / 5;

            xVector += (this.mouseX / 5) * Math.random();
            yVector += (this.mouseY / 5) * Math.random();

            const dist = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
            const vectorDist = Math.sqrt(
                Math.pow(xVector, 2) + Math.pow(yVector, 2),
            );
            if (dist + vectorDist >= 350) {
                xVector = -xVector / 3;
                yVector = -yVector / 3;
            }

            if (dist >= 351) {
                x = getRandomFloat(-200, 200);
                y = getRandomFloat(-200, 200);
            }

            // move particle
            this.geometry.attributes.position.array[i3] = x;
            this.geometry.attributes.position.array[i3 + 1] = y;
            this.geometry.attributes.position.needsUpdate = true;

            // reassign vectors
            this.xVectors[i3] = xVector;
            this.yVectors[i3] = yVector;
        }

        this.renderer.render(this.scene, this.camera);
    }

    handleMouseMove = ({ clientX, clientY }) => {
        this.mouseX = (Math.round((clientX / this.winW) * 100 - 50) * 2) / 100;
        this.mouseY = (Math.round((clientY / this.winH) * 100 - 50) * 2) / 100;
    };

    handleResize = () => {
        const { innerWidth, innerHeight } = window;
        this.winW = innerWidth;
        this.winH = innerHeight;
        const resize =
            window.innerWidth * 0.88 < CANVAS_SIZE
                ? window.innerWidth * 0.88
                : CANVAS_SIZE;
        this.renderer.setSize(resize, resize);
    };

    componentDidMount() {
        this.init();
        this.animate();
        this.handleResize();

        window.addEventListener('mousemove', this.handleMouseMove, {
            passive: true,
        });
        window.addEventListener('resize', this.handleResize, { passive: true });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('mousemove', this.handleMouseMove);
    }

    render() {
        const { className } = this.props;
        return <CanvasEl ref={this.canvas} className={className} />;
    }
}
