import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

function SEO({ description, lang, meta, title, translations = [] }) {
    const schema = {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        'name': 'Ariane Capital',
        'alternateName': 'AC',
        'description': description,
        'url': 'https://arianecapital.com/',
        'logo': 'https://arianecapital.com/images/logo.png',
    };

    return (
        <Helmet
            title={title}
            htmlAttributes={{ lang }}
            meta={[
                { name: 'description', content: description },
                { property: 'og:title', content: title },
                { property: 'og:description', content: description },
                { property: 'og:type', content: 'website' },
                { name: 'twitter:card', content: 'summary' },
                { name: 'twitter:title', content: title },
                { name: 'twitter:description', content: description },
                {
                    name: 'robots',
                    content:
                        process.env.GATSBY_FRONT_URL !==
                            'https://arianecapital.com' && 'noindex, nofollow',
                },
            ]
                .filter((m) => m.content)
                .concat(meta)}
        >
            {translations.map(({ uri, lang: hrefLang }) => (
                <link
                    key={hrefLang}
                    rel="alternate"
                    hreflang={hrefLang.toLowerCase()}
                    href={uri.replace('/fr/homepage/', '/fr/')}
                />
            ))}

            <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
    );
}

SEO.defaultProps = {
    lang: 'en',
    meta: [],
    description: '',
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default SEO;
