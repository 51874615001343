import React, { useEffect, useRef } from 'react';
import { useInView } from "react-intersection-observer";
import styled from 'styled-components';
import colors from '../../styles/colors';
import grid from '../../styles/grid';
import useViewport from '../../utils/useViewport';
import MountainParallax from './MountainParallax';
import { SnowBall } from './SnowBall';
import VerticalLine from './VerticalLine';

const Wrapper = styled.div`
    grid-column: 1/4;
    text-align: center;
    display: grid;
    grid-template-columns: ${grid.templateMobile};
    &:not(.anim){
        .title-anim{
            transform: translateY(50px);
            opacity: 0;
        }
        .p-anim{
            transform: translateY(50px);
            opacity: 0;
        }
    }
    .title-anim{
        transition: all 400ms ease-in-out;
    }
    .p-anim{
        transition: all 400ms ease-in-out 400ms;
    }
    .gradient {
        position: absolute;
        top: 0;
        width: 100%;
        height: ${(props) =>
        props.data.type === 'parallax' ? '275vw' : '100vh'};
        background: linear-gradient(
            to bottom,
            ${colors.dark_blue} -20%,
            ${colors.transparent_dark_blue} 40%,
            ${colors.dark_blue} 90%
        );
        z-index: 2;
    }
    .background {
        overflow: hidden;
        max-height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        img {
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            object-fit: cover;
        }
    }
    .backgrounds {
        overflow: hidden;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 275vw;
        .sky {
            img {
                top: 0;
                left: 0;
                width: 130vw;
                z-index: 0;
            }
        }
        .mountain {
            img {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100vw;
                z-index: 1;
            }
        }
    }
    .texts {
        grid-column: 2/3;
        position: relative;
        width: 100%;
        margin-top: 65vw;
        z-index: 3;
        transform: translateY(-50%);
        h1 {
            letter-spacing: 0.1rem;
        }
        .subtitle {
            font-family: 'Heebo';
            font-size: 1.2rem;
            letter-spacing: 0.2rem;
            padding-top: 5px;
        }
    }
    .snowball {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .verticalLine {
        height: 120px;
        margin-top: 30vw;
        z-index: 2;
    }
    @media screen and (min-width: 800px) {
        grid-column: 1/15;
        grid-template-columns: ${grid.templateDesktop};
        .gradient {
            left: 0;
            height: ${(props) =>
        props.data.type === 'parallax' ? '90vw' : '100vh'};
        }
        .backgrounds {
            /* grid-column: 1/15; */
            height: 90vw;
            .sky {
                width: 100%;
            }
            .mountain {
                img {
                    z-index: 1;
                }
            }
        }
        .texts {
            grid-column: 5/11;
            margin-top: 8vw;
            margin-bottom: 5vw;
            transform: unset;
            .subtitle {
                font-size: 1.4rem;
                letter-spacing: 0.6rem;
                margin-top: 5px;
            }
            .snowball {
                position: absolute;
                top: 50%;
            }
        }
        .verticalLine {
            margin-top: 8vw;
        }
    }
`;

export default function Header({ className, data }) {
    const { width } = useViewport();
    const breakpoint = 800;

    const video = useRef(null)

    useEffect(() => {
        if (video.current) {
            video.current.play();
        }
    }, [])

    function buildBackground() {
        let backgroundPath;
        let alt;
        switch (data.type) {
            case 'parallax':
                return (
                    <div className="backgrounds">
                        <video className="sky" src="/video/background.mp4" ref={video} loop muted playbackRate="-1"></video>
                        {/* <picture className="sky">
                            <source
                                media="(min-width: 800px)"
                                srcSet="/images/header/desktop/sky.jpg"
                            />
                            <img
                                src="/images/header/mobile/sky.jpg"
                                alt="sky"
                            />
                        </picture> */}
                        <MountainParallax className="mountain" />
                    </div>
                );
            case 'city':
                backgroundPath = 'city.jpg';
                alt = 'city';
                break;
            case 'fog_mountain':
                backgroundPath = 'fog_mountain.jpg';
                alt = 'fog mountain';
                break;
            case 'rock':
                backgroundPath = 'rock.jpg';
                alt = 'rock';
                break;
            case 'snow_mountain':
                backgroundPath = 'snow_mountain.jpg';
                alt = 'snow mountain';
                break;
            case 'sunset':
                backgroundPath = 'sunset.jpg';
                alt = 'sunset';
                break;
            case 'trees':
                backgroundPath = 'trees.jpg';
                alt = 'trees';
                break;
            default:
        }
        return (
            <div className="background">
                <picture className="sky">
                    <source
                        media="(min-width: 800px)"
                        srcSet={'/images/header/desktop/' + backgroundPath}
                    />
                    <img
                        src={'/images/header/mobile/' + backgroundPath}
                        alt={alt}
                    />
                </picture>
            </div>
        );
    }
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
        delay: 3500
    });

    return (
        <Wrapper data={data} className={`${className} ${inView ? "anim" : ""}`} ref={ref}>
            {buildBackground()}
            <div className="gradient"></div>
            <div className="texts">
                <h1 className="title-1 title-anim">{data.title}</h1>
                {data.subtitle && <p className="subtitle p-anim">{data.subtitle}</p>}
                <SnowBall className="snowball" isMobile={width > breakpoint ? false : true} />
            </div>
            <VerticalLine className="verticalLine" />
        </Wrapper>
    );
}
